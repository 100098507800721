/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/bootstrap@4.4.1/dist/css/bootstrap.min.css
 * - /npm/aos@2.3.1/dist/aos.min.css
 * - /npm/select2@4.0.13/dist/css/select2.min.css
 * - /npm/chart.js@2.9.3/dist/Chart.min.css
 * - /npm/slick-carousel@1.8.1/slick/slick.css
 * - /npm/flatpickr@4.6.3/dist/flatpickr.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
